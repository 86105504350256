<template>
  <div class="hello">
    <div>
      露露专用哦！
    </div>
    <div style="margin-top: 30px;">
      视频链接放这里：<el-input v-model="videoUrl" clearable style="width:300px;" @input="urlOnChange" @change="urlOnChange" @blur="urlOnChange"></el-input>
    </div>
    <div style="margin-top: 30px;">
      <el-button type="success" round size="mini" :disabled="submitStatus" @click="submit" :loading="submitLoading">{{ submitButtonText }}</el-button>
    </div>
    <div style="margin-top: 30px;">
      <el-image
      style="width: 200px; height: 200px"
      :src="imageUrl"></el-image>
    </div>
    <div v-for="(item,index) in dataList" :key="index" style="margin-top: 30px;">
      识别结果{{index}}：{{ item }}
    <br>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
import Encrypt from 'encryptlong'
import {converter} from '@/utils/api.js'
export default {
  name: 'HelloWorld',
  data(){
    return{
      videoUrl:"",
      submitStatus:true,
      submitButtonText:"赶紧填链接呀",
      images:{
        0:"https://www.aiblogs.top/static/img/9da07c3874d4e62b319158bc2ed1c45c.1.webp",
        1:"https://www.aiblogs.top/static/img/138a6f9e07bce9a3eac2d92d5f9055f8.200.webp",
        2:"https://www.aiblogs.top/static/img/f9d7d1225c733b462920d0707f7015ab.1_a76d81ab6785b5b09940bf9b7d7c4252.webp",
        3:"https://www.aiblogs.top/static/img/1d0b9823c1092d22827b43413b298310.2.webp"
      },
      params:{
        "url":""
      },
      submitLoading:false,
      imageUrl:"",
      dataList:[],
      publicKey:"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkJZWIUIje8VjJ3okESY8stCs/a95hTUqK3fD/AST0F8mf7rTLoHCaW+AjmrqVR9NM/tvQNni67b5tGC5z3PD6oROJJ24QfcAW9urz8WjtrS/pTAfGeP/2AMCZfCu9eECidy16U2oQzBl9Q0SPoz0paJ9AfgcrHa0Zm3RVPL7JvOUzscL4AnirYImPsdaHZ52hAwz5y9bYoiWzUkuG7LvnAxO6JHQ71B3VTzM3ZmstS7wBsQ4lIbD318b49x+baaXVmC3yPW/E4Ol+OBZIBMWhzl7FgwIpgbGmsJSsqrOq3D8IgjS12K5CgkOT7EB/sil7lscgc22E5DckRpMYRG8dwIDAQAB"
    }
  },
  methods:{
    // 使用公钥加密数据
    encrypt(data) {
      const encryptor = new Encrypt()
      encryptor.setPublicKey(this.publicKey)
      // 如果是对象/数组，需要先转换成字符串
      const result = encryptor.encryptLong(JSON.stringify(data))
      return result
    },
    urlOnChange(){
      this.imageUrl = this.images[0]
      if(this.videoUrl.length > 0){
        this.submitButtonText = "可以提交啦";
        this.submitStatus = false;
      }else {
        this.submitButtonText = "赶紧填链接呀";
        this.submitStatus = true;
      }
    },
    async submit(){
      this.dataList = [];
      this.imageUrl = this.images[3]
      this.submitLoading = true;
      this.submitButtonText = "正在找我大哥问结果，骚等~";
      let e = "kedou@8989!63239";
      let t = "a2Vkb3VAODk4OSE2MzIzMw==";
      let r = CryptoJS.enc.Utf8.parse(e)
      this.params.url = this.videoUrl;
      let a = JSON.stringify(this.params);
      let s = CryptoJS.enc.Utf8.parse(atob(t));
      let c = CryptoJS.AES.encrypt(a, r, {
          iv: s,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      }).toString()
      
      const res = await converter(this.encrypt(c));
      console.log(res,'res')
      if(res.code == 0){
        this.imageUrl = this.images[0]
        this.submitButtonText = "快看，成功咯！";
        this.dataList = res.data.list;
        this.imageUrl = this.images[1]
      }else {
        this.imageUrl = this.images[2]
        this.submitButtonText = "好像失败了，你再重新试试";
      }
      this.submitLoading = false;
      console.log(this.dataList,'dataList')
    }
  },
  created(){
    this.imageUrl = this.images[0];
  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
